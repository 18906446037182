.c-outdated {
    z-index: 999;
    background: red;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

    &__wrapper {
        position: absolute;
        background-color: yellow;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}