@font-face {
  font-family: 'bodoni';
  src: url('@/fonts/bodoni/bodoni-book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bodoni';
  src: url('@/fonts/bodoni/bodoni-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@/fonts/open-sans/opensans-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@/fonts/open-sans/opensans-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@/fonts/open-sans/opensans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$bodoni: 'bodoni',
'Bodoni 72',
serif;

$open-sans: 'Open Sans',
sans-serif;

$min-vw-font: 400px;
$max-vw-font: 1200px;

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: $bodoni;
  font-weight: normal;
  line-height: 1;
}

h1 {
  @include fluid(font-size, 40px, 60px, $min-vw-font, $max-vw-font)
}

h2 {
  @include fluid(font-size, 35px, 50px, $min-vw-font, $max-vw-font)
}

h3 {
  @include fluid(font-size, 30px, 40px, $min-vw-font, $max-vw-font)
}

h4 {
  @include fluid(font-size, 25px, 30px, $min-vw-font, $max-vw-font)
}

h5 {
  font-size: rem(20);
  @include fluid(font-size, 16px, 20px, $min-vw-font, $max-vw-font)
}

h6 {
  font-size: rem(10);
}

b,
strong {
  font-family: $open-sans;
  font-weight: 300;
  font-size: rem(16);
  line-height: 1.6;
}

p {
  font-family: $open-sans;
  font-weight: 300;
  font-size: rem(16);
  line-height: 1.6;
}

label {
  font-size: rem(22);
  display: block;
}

p .e-small-body {
  font-size: rem(14);
  line-height: rem(22);
}

a,
button {
  font-family: $open-sans;
  text-decoration: none;
  text-transform: uppercase;
  font-size: rem(16);
  line-height: 1;
  letter-spacing: rem(4);
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  .e-arrow {
    position: relative;
    transition: transform 0.2s ease-in-out;
    margin-left: rem(10);

    &--left {
      transition: transform 0.2s ease-in-out;
      margin-right: rem(10);
    }
  }

  &:hover {
    .e-arrow {
      transform: translateX(rem(10));

      &--left {
        transform: translateX(rem(-10));
      }
    }
  }
}

ol,
ul {
  margin-top: 1.25rem;
  font-family: $open-sans;
  font-weight: 300;
  font-size: rem(16);
  line-height: 1.6;
  list-style-position: outside;
}

li {
  list-style-type: none;
  padding-left: rem(25);

  &::before {
    content: '';
    float: left;
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    width: rem(25);
    margin-left: rem(-25);
  }
}

.e-text {
  &__sm {
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: rem(2);
    font-weight: 300;
  }

  &__xs {
    font-size: rem(12);
    line-height: rem(12);
    letter-spacing: rem(2);
    font-weight: 300;
  }
}