.c-contact {
  display: grid;
  grid-auto-columns: minmax(rem(20), 1fr) minmax(rem(180), rem(400))
    minmax(rem(200), rem(400)) minmax(rem(480), rem(800)) minmax(0, 1fr);
  grid-template-rows: rem(200) rem(500) rem(100);

  &__maps {
    grid-column: 1/6;
    grid-row: 1/3;
    height: 100%;
    z-index: 0;
  }

  &__adres {
    grid-column: 2/3;
    grid-row: 4/5;
  }

  &__contact {
    grid-column: 3/4;
    grid-row: 4/5;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-title {
      position: relative;
      margin-bottom: rem(30);

      &::after {
        position: absolute;
        content: '';
        width: rem(35);
        height: rem(1);
        background-color: $white;
        left: 0;
        bottom: rem(-8);
      }
    }
  }

  &__form {
    grid-row: 2/5;
    grid-column: 4/5;
    z-index: 1;
    background-color: $dark-blue;
    padding: rem(100) rem(100) 0 rem(100);

    &-title {
      margin-bottom: rem(40);
    }
  }

  &__submit {
    display: block;
    margin-top: rem(40);
  }

  &__link {
    color: $white;

    &--bottom {
      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }
  }

  @media (max-width: 1050px) {
    &__form {
      padding: rem(70) rem(70) 0 rem(70);
    }
  }

  @media (max-width: 950px) {
    grid-auto-columns: rem(20) 1fr rem(20);
    grid-template-rows: 50vh rem(60) auto rem(60) auto rem(60) auto;

    &__maps {
      grid-column: 1/4;
      grid-row: 1/2;
    }

    &__form {
      padding: 0;
      grid-column: 2/3;
      grid-row: 3/4;
    }

    &__adres {
      grid-column: 2/3;
      grid-row: 5/6;
    }

    &__contact {
      grid-column: 2/3;
      grid-row: 7/8;
    }
  }
}
