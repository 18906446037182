.c-block-big {
    display: grid;
    grid-template-rows: rem(760);
    align-items: center;
    overflow: hidden;

    &--left {
        grid-template-areas: 'img img . content . .';
        grid-template-columns:
            1fr minmax(rem(350), rem(740)) minmax(rem(40), rem(160)) minmax(rem(400), rem(600)) minmax(rem(20), rem(100)) 1fr;
    }

    &--right {
        grid-template-areas: '. . content . img img';
        grid-template-columns:
            1fr minmax(rem(20), rem(100)) minmax(rem(400), rem(600)) minmax(rem(40), rem(160)) minmax(rem(350), rem(740)) 1fr;
    }

    &__title {
        margin-bottom: rem(20);
    }

    &__content {
        grid-area: content;
        z-index: 0;
    }

    &__img {
        position: relative;
        grid-area: img;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text {
        margin-bottom: rem(40);
    }

    @media (max-width: 800px) {
        grid-template-columns: rem(20) 1fr rem(20);
        grid-template-rows: 100vw rem(30) auto;
        grid-template-areas:
            'img img img'
            '. . .'
            '. content .';

        &__img::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0) 50%, rgba(0, 0, 0, 0.5) 100%)
        }
    }
}