@function rem($size) {
  $result: ($size/10) + rem;
  @return $result;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@function fluid($min-vw, $max-vw, $min, $max) {
  $result: calc(
    #{$min+'px'} + (#{$max} - #{$min}) * ((100vw - #{$min-vw+'px'}) /
          (#{$max-vw} - #{$min-vw}))
  );
  @return $result;
}
