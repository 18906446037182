.js-reveal {
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
