.c-mobile-nav {
  display: none;
  width: 100%;
  position: fixed;
  height: 100vh;
  background-color: $dark-blue;
  justify-content: center;
  align-items: center;

  transform: translate3d(0, -100vh, 0);
  z-index: 1;

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    color: $white;
    font-weight: 300;
    font-size: rem(26);
    line-height: 1;
    overflow: hidden;

    &--active {
      font-weight: 400;
      color: $light-gold;
    }

    &--animation {
      display: block;
      transform: translate(0, 100%);
    }

    &:not(:last-child){
      margin-bottom: rem(30);
    }
  }
}
