.c-footer {
  background-color: $white;

  &__wrapper {
    margin: 0 auto;
    max-width: rem(1640);
    padding: rem(150) rem(20) rem(40) rem(20);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(100);
  }

  &__logo {
    width: rem(182);
    margin-bottom: rem(20);
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }

  &__contact-item {
    margin-bottom: rem(10);
    color: #222529;
  }

  &__socials {
    margin-top: rem(30);

    &--space {
      margin-right: rem(30);
    }
  }

  &__nav {
    align-self: center;
  }

  &__nav-item {
    color: $dark-blue;

    &:not(:last-child) {
      margin-right: rem(40);
    }

    &--active {
      font-weight: 400;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__desc {
    white-space: nowrap;
    color: $dark-blue;

    &--mr-1 {
      margin-right: rem(20);
    }

    &--mr-2 {
      margin-right: rem(5);
    }
  }

  &__mobile {
    display: none;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(40);
  }

  @media only screen and (max-width: 1000px) {
    &__nav-item {
      &:not(:last-child) {
        margin-right: rem(15);
      }
    }
  }

  @media only screen and (max-width: 850px) {
    &__nav {
      display: flex;
      flex-direction: column;
    }

    &__nav-item {
      &:not(:last-child) {
        margin: 0 0 rem(15) 0;
      }
    }

    &__bottom {
      flex-direction: column;
    }

    &__desc {
      display: block;

      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }

    &__links {
      margin-bottom: rem(40);
    }
  }

  @media only screen and (max-width: 450px) {
    &__wrapper {
      padding: rem(100) rem(20) rem(30) rem(20);
    }

    &__content {
      display: none;
    }

    &__mobile {
      display: block;
    }

    &__socials {
      margin-top: 0;
    }

    &__logo {
      margin-bottom: 0;
    }

    &__desc {
      color: $dark-blue;

      &--mr-1 {
        margin-right: rem(10);
      }
    }
  }
}
