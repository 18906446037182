$breakpoints: (
  'xs': 320px,
  's': 400px,
  'm': 800px,
  'l': 1000px,
  'xl': 1600px,
  'xxl': 1920px,
) !default;

$screens: ('xs': (max-width: map-get($breakpoints, xs)),
  's': (max-width: map-get($breakpoints, s)),
  'm': (max-width: map-get($breakpoints, m)),
  'l': (max-width: map-get($breakpoints, l)),
  'xl': (max-width: map-get($breakpoints, xl)),
  'xxl': (max-width: map-get($breakpoints, xxl)),
);

@mixin screen($screen) {
  @if map-has-key($screens, $screen) {
    @media #{inspect(map-get($screens, $screen))} {
      @content;
    }
  }
}

@mixin fluid($property, $min-size, $max-size, $min-vw, $max-vw) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      #{$property}: $min-size;

      @media screen and (min-width: $min-vw) {
        #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        #{$property}: $max-size;
      }
    }
  }
}