.c-nav {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  // For lang switcher
  //grid-template-columns: minmax(rem(20), 1fr) minmax(0, rem(1500)) rem(100) minmax(rem(20), 1fr);
  grid-template-columns: minmax(rem(20), 1fr) minmax(0, rem(1600)) minmax(rem(20), 1fr);
  grid-template-rows: rem(25) auto rem(25);
  z-index: 2;

  &__logo{
    height: rem(34);
    transition: height .2s ease-in-out;
  }

  &__home {
    line-height: 0;
  }

  &__content {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  &__lang {
    &--mobile {
      opacity: 0;
      display: none;
      margin-right: rem(18);
    }

    &--desktop {
      grid-column: 3/4;
      grid-row: 2/3;
      justify-self: right;
      align-self: end;
    }
  }

  &__right {
    display: none;
    align-items: center;
  }

  &__sub {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(19);

    &-item {
      color: $grey;

      &:not(:last-child) {
        margin-right: rem(30);
      }

      &--active {
        font-weight: 400;
        color: $light-gold;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      color: $white;
      font-weight: 300;

      &:not(:last-child) {
        margin-right: rem(40);
      }

      &--active {
        font-weight: 700;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: rem(5);
          height: rem(5);
          top: rem(35);
          left: 50%;
          transform: translateX(-50%);
          background-color: $light-gold;
          border-radius: 100%;
        }
      }
    }
  }

  &__select {
    display: block;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(16);
    letter-spacing: rem(4);
    text-decoration: none;
    text-transform: uppercase;
    color: $white;
    padding-right: 10px;
    background-image: url('@/images/icons/dropdown.svg');
    background-position: 100%;
    background-repeat: no-repeat;

    &--desktop {
      margin-bottom: rem(10);
    }
  }

  &__hamburger {
    display: block;
    width: rem(40);
    height: rem(40);
    padding: rem(16) rem(8);
    cursor: pointer;
    margin-right: rem(-8);
    -webkit-tap-highlight-color: transparent;

    &--active & {
      &--line {
        &::before {
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }

    &--line {
      position: relative;

      &::before,
      &::after {
        content: '';
        width: 100%;
        height: rem(1.2);
        background: $white;
        position: absolute;
        left: 0;
        transition: transform 200ms ease-in-out, top 200ms ease-in-out;
      }

      &::before {
        top: 0;
      }

      &::after {
        top: rem(8);
      }
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: minmax(rem(20), 1fr) minmax(0, rem(1500)) rem(60) minmax(rem(20),
        1fr);

    &__sub {
      &-item:not(:last-child) {
        margin-right: rem(15);
      }
    }

    &__main {
      &-item:not(:last-child) {
        margin-right: rem(15);
      }
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: rem(20) 1fr rem(20);
    grid-template-rows: rem(12) auto rem(12);

    &__sub {
      display: none;
    }

    &__main {
      &-items {
        display: none;
      }
    }

    &__lang--desktop {
      display: none;
    }

    &__right {
      display: flex;
    }

    &__logo {
      height: rem(25);
    }
  }

  @media (min-width: 800px) {
    &--active & {
      grid-template-rows: rem(18) auto rem(18);

      &__logo {
        height: rem(28);
      }

      &__main {
        &-item {
          &--active {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}