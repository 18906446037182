.c-intro {
  display: grid;
  grid-template-columns: minmax(rem(20), 1fr) minmax(0, rem(800)) minmax(rem(400), rem(800)) minmax(rem(20), 1fr);
  grid-template-rows: rem(300) auto;
  overflow-x: hidden;

  &__gradient {
    position: relative;
    width: 100%;
    grid-column: 1/5;
    grid-row: 1/3;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%), var(--image-url) no-repeat center center;
      background-size: cover;
    }
  }

  &__overlap {
    position: relative;
    justify-self: end;
    align-self: end;
    background-color: $white;
    grid-column: 3/5;
    grid-row: 2/3;
    z-index: 1;
    width: 100%;
    padding-left: fluid(800, 1920, 100, 200);
    padding-top: fluid(800, 1920, 75, 150);
  }

  &__content {
    max-width: rem(600);
  }

  &__title {
    margin-bottom: rem(20);
  }

  @media screen and (min-width: 1920px) {
    &__overlap {
      padding-left: rem(200);
      padding-top: rem(150);
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: rem(20) 1fr rem(20);
    grid-template-rows: rem(400) rem(30) auto;

    &__gradient {
      grid-row: 1/2;
    }

    &__overlap {
      grid-column: 2/3;
      grid-row: 3/4;
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
}