.c-spacer {
    &-s {
        @include fluid(height, 60px, 100px, map-get($breakpoints, m), map-get($breakpoints, xxl));
    }

    &-m {
        @include fluid(height, 80px, 150px, map-get($breakpoints, m), map-get($breakpoints, xxl));
    }

    &-l {
        @include fluid(height, 100px, 200px, map-get($breakpoints, m), map-get($breakpoints, xxl));
    }

    &-nav {
        @include fluid(height, 120px, 220px, map-get($breakpoints, m), map-get($breakpoints, xxl));

        @include screen(m) {
            height: 0;
        }
    }
}