.toastify {
  position: fixed;
  z-index: 999;
  display: block;
  cursor: pointer;
  max-width: rem(500);
  text-decoration: none;
  padding: rem(25);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.2, 0.6, 0.3, 1);
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  margin-right: auto;
  left: rem(20);
  right: rem(20);
  font-family: $open-sans;
  font-weight: normal;
  line-height: 1;
  font-size: rem(16);
  font-weight: 700;
  color: $white;

  &.on {
    opacity: 1;
  }

  &-error {
    background: #ff0533;
  }

  &-success {
    background: #6dcf1d;
  }

  @media (max-width: 950px) {
    padding: rem(20);
  }
}