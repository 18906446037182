@charset "UTF-8";

$color-white: #fff;
$color-light-grey: #eee;
$color-grey: #8a8a8a;
$color-black: #111;

.g-accordion {
  $accordion: &;
  margin-bottom: rem(60);

  &__item {
    border-bottom: rem(0.7) solid $dark-blue;
    background-color: $white;

    &--trigger {
      font-family: 'Open Sans', sans-serif;
      font-size: rem(14);
      line-height: rem(14);
      letter-spacing: rem(2);
      font-weight: 600;
      color: $dark-blue;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
      padding: rem(20) 0;
      display: block;
      cursor: pointer;
      background-color: transparent;
      transition: color 0.05s ease;
      position: relative;
      text-decoration: none;
      margin: 0;
      border: 0;

      &:focus {
        outline: none;
      }

      &::after {
        content: '+';
        font-size: rem(20);
        text-align: center;
        width: rem(20);
        transform: translate(0, -50%);
        position: absolute;
        right: rem(10);
        top: 50%;
      }
    }

    &--active {
      #{$accordion}__content {
        visibility: visible;
      }
    }

    &--active & {
      &--trigger {
        color: $gold;
        &::after {
          content: '\2013';
        }
      }
    }
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &__content {
    visibility: hidden;
    overflow: hidden;
    transition-property: height, visibility;
    transition-timing-function: ease;
  }

  &__inner {
    padding: rem(10) 0 rem(30) 0;
  }
}
