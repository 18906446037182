.c-block-overlap {
    display: grid;
    grid-template-columns:
        minmax(rem(20), 1fr) minmax(rem(250), rem(490)) minmax(rem(100), rem(250)) minmax(rem(40), rem(160)) minmax(rem(350), rem(500)) minmax(rem(20), 1fr);
    grid-template-rows: rem(100) rem(600) rem(100);
    align-items: center;

    &__title {
        margin-bottom: rem(20);
    }

    &__blue {
        background-color: $dark-blue;
        grid-column: 1/3;
        grid-row: 1/4;
        height: 100%;
    }

    &__img {
        grid-column: 2/4;
        grid-row: 2/3;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    &__content {
        grid-column: 5/6;
        grid-row: 2/3;
    }

    &__text {
        margin-bottom: rem(40);
    }

    @media (max-width: 850px) {
        grid-template-columns: rem(20) 3fr 1fr rem(20);
        grid-template-rows: auto rem(60) rem(40) 80vw rem(40);

        &__blue {
            grid-column: 1/3;
            grid-row: 3/6;
        }

        &__img {
            grid-column: 2/4;
            grid-row: 4/5;
        }

        &__content {
            grid-column: 2/4;
            grid-row: 1/2;
        }
    }
}