.c-block-scroll {

    &__title {
        margin-bottom: rem(20);
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: rem(1640);
        padding: 0 rem(20);
        margin: 0 auto rem(60) auto;
    }

    &__bottom {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__content {
        max-width: rem(600);
        z-index: 0;
    }

    &__aspect-ratio {
        width: rem(850);
        margin-left: auto;
    }

    &__ratio {
        position: relative;
        padding-top: calc((3 / 4) * 100%);
    }

    &__img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
    }

    &__text {
        font-family: 'bodoni', 'Bodoni 72', serif;
        font-weight: normal;
        margin-top: -8vw;
        position: absolute;
        opacity: 0.02;
        user-select: none;
        font-size: 39vw;
        line-height: 27vw;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
    }

    @media (max-width: 950px) {
        &__top {
            flex-direction: column;
            align-items: flex-start;
        }

        &__content {
            max-width: 100%;
            margin-bottom: rem(40);
        }
    }
}