.c-products {
  display: grid;
  max-width: rem(1640);
  padding: rem(100) rem(20) rem(50) rem(20);
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  gap: rem(65);

  &__item {
    position: relative;
    padding-top: calc((1 / 1) * 100%);
    background-color: #f6f6f6;
  }

  &__img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1920px) {
    gap: fluid(400, 1920, 10, 65);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    padding: rem(20);
  }
}
