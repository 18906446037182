.c-block {
    &-banner {
        padding-left: fluid(1400, 1920, 20, 140);
        padding-right: fluid(1400, 1920, 20, 140);
        padding-top: fluid(400, 1920, 100, 200);
        margin: 0 rem(20);
        display: grid;
        grid-template-rows: auto auto auto;

        &__title {
            font-size: 16vw;
            font-family: 'bodoni', 'Bodoni 72', serif;
            font-weight: normal;
            opacity: 0.1;
            user-select: none;
            line-height: 1;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            z-index: 1;
            grid-row: 1/3;
            grid-column: 1;
        }

        &__img {
            width: 100%;
            height: fluid(400, 1920, 400, 800);
            object-fit: cover;
            opacity: 0.6;
            grid-row: 2/4;
            grid-column: 1;
        }

        &__scroll {
            grid-column: 2/3;
            grid-row: 3/4;
        }
    }

    &-text {
        max-width: rem(1440);
        padding: 0 rem(20);
        display: flex;
        margin: 0 auto;

        &__title {
            margin-bottom: rem(20);
        }

        &__content {
            z-index: 0;
        }
    }
}