.c-heading {
  max-width: rem(1640);
  margin: 0 auto;
  padding-right: rem(20);
  padding-left: rem(20);
  @include fluid(padding-bottom, 80px, 120px, map-get($breakpoints, m), map-get($breakpoints, xxl));
  @include fluid(padding-top, 110px, 235px, map-get($breakpoints, m), map-get($breakpoints, xxl));

  &__back {
    display: block;
    margin-bottom: rem(20);
  }

  &__content {
    max-width: rem(800);
  }

  &__title {
    margin-bottom: rem(20);
  }
}
