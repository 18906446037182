.c-certificates {
  max-width: rem(1640);
  margin: 0 auto;
  padding: 0 rem(20);

  &:not(:last-child) {
    margin-bottom: rem(60);
  }

  &__item {
    display: grid;
    grid-template-columns:
      minmax(rem(100), rem(250)) minmax(rem(20), rem(50)) minmax(rem(400), rem(900)) rem(20) 1fr;

    &--mb {
      @include fluid(margin-bottom, 100px, 150px, map-get($breakpoints, m), map-get($breakpoints, xxl));
    }
  }

  &__logo {
    grid-column: 1/2;
    width: 100%;
    position: relative;
    padding-top: calc((1 / 1) * 100%);
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    grid-column: 3/4;
    align-self: center;
  }

  &__title {
    margin-bottom: rem(20);
  }

  &__text {
    margin-bottom: rem(30);
  }

  &__label {
    font-family: $open-sans;
    font-size: rem(14);
    font-weight: 600;
    color: $light-gold;
    line-height: 1.8;

    &:not(:last-child) {
      margin-right: rem(20);
    }
  }

  &__link {
    display: flex;
    grid-column: 5/6;
    justify-self: end;
    align-self: center;
  }

  @media (max-width: 900px) {
    &__item {
      grid-template-columns: 1fr;
      grid-template-rows: auto rem(30) auto rem(40) auto;
    }

    &__logo {
      padding-top: calc((3 / 4) * 100%);
      grid-column: 1/2;
      grid-row: 1/2;
    }

    &__content {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    &__link {
      grid-column: 1/2;
      grid-row: 5/6;
      justify-self: start;
    }

    &__img {
      width: 40vw;
    }
  }
}