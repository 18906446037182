.o-blue {
  background-color: $dark-blue;
  color: $white;

  &__link {
    color: $light-gold;

    &--arrow {
      fill: $light-gold;
    }
  }

  .u-title {
    &::before {
      color: $blue;
    }
  }

  li::before {
    color: $light-gold;
  }
}

.o-white {
  background-color: $white;

  &__link {
    color: $gold;

    &--arrow {
      fill: $gold;
    }
  }

  .u-title {
    &::before {
      color: $light-grey;
    }
  }

  li::before {
    color: $gold;
  }
}