.c-video {
  display: grid;
  grid-template-columns: 1fr minmax(0, rem(1600)) 1fr;
  grid-template-rows: auto rem(250);

  &__wrapper {
    grid-column: 2/3;
    grid-row: 1/3;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  &__controls {
    position: relative;
    padding-bottom: 200%;
    transform: translateY(-35.95%);
    background: $dark-blue;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__white {
    background-color: $white;
    grid-column: 1/4;
    grid-row: 2/3;
  }

  @include screen('s') {
    grid-template-rows: auto;

    &__wrapper {
      grid-row: 1/2;
    }

    &__white {
      display: none;
    }
  }
}