.c-block-abstract {
    display: grid;
    grid-template-columns:
        1fr minmax(rem(300), rem(785)) minmax(rem(30), rem(55)) minmax(rem(40), rem(160)) minmax(rem(400), rem(600)) minmax(rem(20), 1fr);
    grid-template-rows: 1fr auto rem(60) auto 1fr;

    &__img {
        grid-column: 1/4;
        grid-row: 1/6;
        object-fit: cover;
        height: rem(750);
        width: 100%;
    }

    &__overlap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column: 3/7;
        grid-row: 2/3;
    }

    &__title[copy] {
        display: block;
        position: relative;
        top: 8%;
        font-size: rem(150);
        white-space: nowrap;
        margin-bottom: 0;
        z-index: 0;

        &::before {
            content: attr(copy);
            position: absolute;
            top: 45%;
            left: 4%;
            font-size: rem(100);
            color: $blue;
            z-index: -1;
        }
    }

    &__line {
        position: relative;
        top: 15%;
        display: block;
        background-color: $white;
        margin-left: 10%;
        width: 100%;
        height: rem(1);
    }

    &__text {
        margin-bottom: rem(40);
    }

    &__content {
        align-self: center;
        grid-column: 5/6;
        grid-row: 4/5;
    }

    @media (max-width: 850px) {
        grid-template-columns: rem(20) 1fr rem(20);
        grid-template-rows: auto rem(20) auto rem(60) 100vw;

        &__img {
            grid-column: 1/4;
            grid-row: 5/6;
            height: 100%;
        }

        &__overlap {
            align-self: top;
            grid-column: 2/3;
            grid-row: 1/2;
        }

        &__content {
            grid-column: 2/3;
            grid-row: 3/4;
        }

        &__line {
            display: none;
        }

        &__title[copy] {
            font-size: rem(40);

            &::before {
                display: none;
            }
        }
    }
}