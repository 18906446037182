.u-title[title] {
  position: relative;

  &::before {
    content: attr(title);
    position: absolute;
    left: 0;
    transform: translate(-50%, -35%);
    z-index: -1;
    user-select: none;
    font-size: rem(200);
  }

  @media only screen and (max-width: 850px) {
    &::before {
      display: none;
    }
  }
}
