.c-forms {
  &__group {
    margin-bottom: rem(25);
  }

  &__label {
    margin-bottom: rem(8);
  }

  &__field {
    display: block;
    border: 0;
    text-decoration: none;
    padding: 0;
    padding: rem(15) rem(20);
    width: 100%;
    font-family: $open-sans;
    font-size: rem(16);
    font-weight: 300;
    background: $blue;
    color: $white;
    outline: none;
    transition: background 0.3s ease-in-out;

    &:focus {
      background: $white;
      color: $dark-blue;
    }

    &--resize {
      resize: none;
      border-radius: 0;
    }
  }
}