.js-lazy {
    &__preload {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: $white;
            transition: background 0.6s ease-in-out;
        }

        &--active {
            &::after {
                content: '';
                background: transparent;
            }
        }
    }
}