.c-block-minimal {
    $minimal: &;
    display: grid;

    &--left {
        grid-template-columns:
            minmax(rem(20), 1fr) minmax(0, rem(100)) minmax(rem(350), rem(600)) minmax(rem(40), rem(160)) minmax(rem(390), rem(740)) minmax(rem(20), 1fr);

        #{$minimal} {
            &__content {
                grid-column: 3/4;
            }

            &__aspect-ratio {
                grid-column: 5/6;
            }
        }
    }

    &--right {
        grid-template-columns:
            minmax(rem(20), 1fr) minmax(rem(390), rem(740)) minmax(rem(40), rem(160)) minmax(rem(350), rem(600)) minmax(0, rem(100)) minmax(rem(20), 1fr);

        #{$minimal} {
            &__content {
                grid-column: 4/5;
            }

            &__aspect-ratio {
                grid-column: 2/3;
            }
        }
    }

    &--mb {
        &-s {
            @include fluid(margin-bottom, 120px, 150px, map-get($breakpoints, m), map-get($breakpoints, xxl));
        }
        &-l{
            @include fluid(margin-bottom, 120px, 200px, map-get($breakpoints, m), map-get($breakpoints, xxl));
        }

    }

    &__link {
        display: block;
        margin-top: rem(40);
    }

    &__content {
        z-index: 0;
        align-self: center;
        grid-row: 1/2;
    }

    &__aspect-ratio {
        grid-row: 1/2;
        position: relative;

        &--1 {
            padding-top: calc((1/1) * 100%);
        }

        &--2 {
            padding-top: calc((4/3) * 100%);
        }
    }

    &__img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__title {
        margin-bottom: rem(20);
    }

    @media (max-width: 800px) {
        grid-template-rows: auto rem(30) auto;
        grid-template-columns: rem(20) 1fr rem(20);

        &--left &,
        &--right & {
            &__content {
                grid-column: 2/3;
                grid-row: 3/4;
            }

            &__aspect-ratio {
                grid-column: 2/3;
                grid-row: 1/2;
                height: 100%;
            }
        }
    }
}